<template>
  <div>
    <ListarFormularios :codigo-formulario="'SATISFACCION-SALUD'" />
  </div>
</template>

<script>
import ListarFormularios from '@/views/administrador/gestion-formulario/satisfaccion-estudiante/FormulariosSegunCodigo.vue'

export default {
  components: {
    ListarFormularios,
  },

}
</script>
